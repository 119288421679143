<template>
    <div>
        <!-- Home Start-->
        <section class="main-header bg-header">
            <div class="home-bg-img">
                <div class="container">
                    <div class="owl-carousel owl-theme main-slider">
                        <div class="item">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="content wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1s">
                                        <h2 class="banner-title text-lg-left text-center text-white text-uppercase">
                                            GROW YOUR BUSINESS<br>WITH US
                                        </h2>
                                        <p class="text-white text-lg-left text-center para-txt font-weight-medium f-16">
                                          We analyze your business and provide you with the best solution.<br>
                                          Specialized in web design, development, and digital marketing.
                                        </p>
                                        <div class="mt-md-4 mb-4 text-lg-left text-center">
                                            <a href="#" class="btn theme-btn text-white">GET STARTED</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="banner-img-02 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                        <img src="assets/images/main-banner-01.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Home End -->
        <!-- Start Aboutus -->
        <section class="section bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-lg-0 mt-sm-4 mt-0">
                            <div class="hover-block aboutus-inner transition p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="about-inner-main text-center">
                                    <div class="about-inner-text">
                                        <h2 class="f-40 theme-color text-md-left text-center">01.</h2>
                                        <h5 class="my-3 theme-color text-md-left text-center font-weight-medium">Website Design</h5>
                                        <p class="para-text text-md-left text-center mb-0">
                                          Our website design process starts with understanding your brand and audience. We create visually appealing and user-friendly designs that reflect your business values and engage your customers. Our goal is to craft a unique and immersive experience that stands out in the digital landscape.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-lg-0 mt-4">
                            <div class="hover-block aboutus-inner transition p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="about-inner-main text-center">
                                    <div class="about-inner-text">
                                        <h2 class="f-40 theme-color text-md-left text-center">02.</h2>
                                        <h5 class="my-3 theme-color text-md-left text-center font-weight-medium">Website Development</h5>
                                        <p class="para-text text-md-left text-center mb-0">
                                          In the development phase, we turn your design into a fully functional website. Using the latest technologies and best practices, our team ensures your site is fast, responsive, and secure. We focus on seamless navigation and robust performance to provide an exceptional user experience across all devices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-lg-0 mt-4">
                            <div class="hover-block aboutus-inner transition p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="about-inner-main text-center">
                                    <div class="about-inner-text">
                                        <h2 class="f-40 theme-color text-md-left text-center">03.</h2>
                                        <h5 class="my-3 theme-color text-md-left text-center font-weight-medium">Launch Website</h5>
                                        <p class="para-text text-md-left text-center mb-0">
                                          Launching your website is an exciting milestone. We handle everything from final testing and quality assurance to deploying your site live. Our comprehensive launch plan includes setting up hosting, configuring domain settings, and ensuring everything runs smoothly. Post-launch, we provide support to make sure your site continues to perform at its best.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-bottom-section mt-5 wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s">
                    <div class="title-section">
                        <div class="subtitle-hr d-flex align-items-center">
                            <h6 class="subtitle theme-color text-uppercase position-relative">ABOUT</h6>
                            <hr>
                        </div>
                        <h2 class="title text-md-left text-center f-40">About WEST AMY</h2>
                    </div>
                    <div class="mt-4">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <img src="assets/images/aboutus/about-img.png" alt="about-img.png" class="w-100 img-fluid">
                            </div>
                            <div class="col-lg-7 mt-lg-0 mt-4">
                                <h1 class="font-sm-24">Complete Business Solutions in One Place</h1>
                                <p>At West AMY, we provide a comprehensive suite of digital services tailored to meet all your business needs. From cutting-edge website design and development to strategic digital marketing and robust IT support, we are your one-stop shop for all things digital.</p>
                                <p>Our team of experts is dedicated to delivering innovative solutions that drive growth and efficiency, helping your business thrive in the digital age. With our holistic approach, you can rest assured that every aspect of your digital presence is handled with professionalism and expertise, allowing you to focus on what you do best - running your business.</p>
                                <ul class="about-list list-unstyled pl-4 mb-0">
                                    <li class="position-relative mb-4">Holistic Approach: Comprehensive digital solutions from design to deployment.</li>
                                    <li class="position-relative mb-4">Expert Team: Dedicated professionals ensuring top-quality service.</li>
                                    <li class="position-relative">Business Growth: Innovative strategies to drive efficiency and success.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Aboutus -->
        <!-- Start Features section -->
        <section class="section bg-light">
            <div class="container">
                <div class="title-section">
                    <div class="subtitle-hr d-flex align-items-center">
                        <h6 class="subtitle theme-color text-uppercase position-relative">SERVICES</h6>
                        <hr>
                    </div>
                    <h2 class="title text-md-left text-center f-40">Check The Features</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-main text-center">
                                    <div class="features-inner-text text-sm-left text-center">
                                        <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                            <span class="icon transition mdi mdi-desktop-mac-dashboard d-flex align-items-center justify-content-center text-white f-40"></span>
                                        </div>
                                        <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Clean Design</h5>
                                        <p class="para-text text-sm-left text-center mb-0">We create visually appealing, clutter-free websites that are easy to navigate, ensuring a seamless user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-responsive d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Responsive Design</h5>
                                    <p class="para-text text-sm-left text-center mb-0">Our responsive design ensures your website looks and functions perfectly on any device, across smartphones, tablets, and desktops.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-rocket d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Fast Boot</h5>
                                    <p class="para-text text-sm-left text-center mb-0">We ensure your website loads quickly, providing users with a seamless browsing experience from the moment they arrive.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-chat-processing d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Chatting Service</h5>
                                    <p class="para-text text-sm-left text-center mb-0">Our chatting service enables real-time communication with your customers, providing instant support and enhancing user engagement on your website.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-chart-areaspline d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Analysis</h5>
                                    <p class="para-text text-sm-left text-center mb-0">Our comprehensive analysis services provide valuable insights into your digital performance, helping you make informed decisions to drive growth and success.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition  mdi mdi-face-agent d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Quick Support</h5>
                                    <p class="para-text text-sm-left text-center mb-0">Our quick support service ensures timely assistance and resolution of any issues, providing you with peace of mind and uninterrupted operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Features section -->
        <!-- Start FAQ's And Meet Our Team -->
        <section class="section bg-light" id="faq-here">
            <div class="container">
                <div class="row align-items-baseline">
                    <div class="col-lg-6 wow fadeInUp" data-wow-duration="1500ms">
                        <div class="title-section">
                            <div class="subtitle-hr d-flex align-items-center">
                                <h6 class="subtitle theme-color text-uppercase position-relative">FAQ's</h6>
                                <hr>
                            </div>
                            <h2 class="title text-md-left text-center f-40">Ask Question?</h2>
                        </div>
                        <div class="mt-4">
                            <div id="accordion" class="wow fadeInUp" data-wow-duration="1500ms">
                                <div class="accordion-card bg-white mb-4 shadow rounded active">
                                    <div class="card-header border-0 p-0" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span class="font-weight-medium"> What payment methods do you accept?</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                              We accept payments via credit/debit card (Visa, Mastercard, American Express) and bank transfers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-card bg-white mb-4 shadow rounded">
                                    <div class="card-header border-0 p-0" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span class="font-weight-medium"> How long does the website development process take?</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                                The development time can vary depending on the complexity of the project. Typically, the process can take between 4 to 8 weeks from conception to launch.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-card bg-white mb-4 shadow rounded">
                                    <div class="card-header border-0 p-0" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span class="font-weight-medium"> Do you offer maintenance services after the website is launched?</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                              Yes, we provide ongoing maintenance services to ensure your website runs smoothly and stays up-to-date with the latest technologies and trends.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-card bg-white mb-4 shadow rounded">
                                    <div class="card-header border-0 p-0" id="headingFour">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span class="font-weight-medium"> What should I do if I encounter any technical issues with my website?</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                              If you experience any technical issues, feel free to reach out to our technical support team through the contact form on our website or by emailing us at info@westamy.com. We'll be happy to assist you in resolving any issues you encounter.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-lg-0 mt-4">
                        <div class="wow fadeInUp" data-wow-duration="1500ms">
                            <div class="title-section">
                                <div class="subtitle-hr d-flex align-items-center">
                                    <h6 class="subtitle theme-color text-uppercase position-relative">TEAM</h6>
                                    <hr>
                                </div>
                                <h2 class="title text-md-left text-center f-40">Meet our Team</h2>
                            </div>
                        </div>
                        <div class="mt-4 wow fadeInUp" data-wow-duration="1500ms">
                            <div class="row team-slider owl-carousel owl-theme">
                                <div class="col-12">
                                    <div class="border bg-white transition text-center p-3">
                                        <div class="rounded-circle overflow-hidden team-img mx-auto">
                                            <img src="assets/images/team/team-01.png" class="rounded-circle img-fluid" alt="team-01.png">
                                        </div>
                                        <div class="team-desc">
                                            <h6 class="f-18 my-2 theme-color">Amanda R</h6>
                                            <p class="my-2 f-16">CEO & SUPPORT</p>
<!--                                            <a href="#" class="mx-2 d-inline-block social-icon transition">
                                                <i class="mdi theme-color mdi-facebook transition f-34"></i>
                                            </a>
                                            <a href="#" class="mx-2 d-inline-block social-icon transition">
                                                <i class="mdi theme-color mdi-twitter transition f-34"></i>
                                            </a>
                                            <a href="#" class="mx-2 d-inline-block social-icon transition">
                                                <i class="mdi theme-color mdi-google-plus transition f-34"></i>
                                            </a>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="border bg-white transition text-center p-3">
                                        <div class="rounded-circle overflow-hidden team-img mx-auto">
                                            <img src="assets/images/team/team-02.jpeg" class="rounded-circle img-fluid" alt="team-02.jpeg">
                                        </div>
                                        <div class="team-desc">
                                            <h6 class="f-18 my-2 theme-color">Joe G</h6>
                                            <p class="my-2 f-16">Developer</p>
<!--                                            <a href="#" class="mx-2 d-inline-block social-icon transition">
                                                <i class="mdi theme-color mdi-facebook transition f-34"></i>
                                            </a>
                                            <a href="#" class="mx-2 d-inline-block social-icon transition">
                                                <i class="mdi theme-color mdi-twitter transition f-34"></i>
                                            </a>
                                            <a href="#" class="mx-2 d-inline-block social-icon transition">
                                                <i class="mdi theme-color mdi-google-plus transition f-34"></i>
                                            </a>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End FAQ's -->
        <!-- Satrt Contact us -->
        <section class="section contactus-section" id="contact-here">
            <div class="container">
                <div class="title-section wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1s">
                    <div class="subtitle-hr d-flex align-items-center">
                        <h6 class="subtitle theme-color text-uppercase position-relative">CONTACT</h6>
                        <hr>
                    </div>
                    <h2 class="title text-md-left text-center f-40">Get In Touch</h2>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1s">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-12 col-sm-4 col-6 py-4 contact-item">
                                    <div class="row align-items-center">
                                        <div class="col-lg-2 col-md-3">
                                            <div class="text-md-left text-center">
                                                <div class="icon-block transition bg-light d-inline-block rounded-circle p-3">
                                                    <span class="icon  mdi mdi-phone d-flex align-items-center justify-content-center theme-color transition"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-md-9">
                                            <div class="text-md-left text-center mt-lg-0 mt-4">
                                                <h5 class="theme-color f-20">Email:</h5>
                                                <a href="mailto:info@westamy.com" class="text-dark text-chat">info@westamy.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-4 col-6 py-4 contact-item">
                                    <div class="row align-items-center">
                                        <div class="col-lg-2 col-md-3">
                                            <div class="text-md-left text-center">
                                                <div class="icon-block transition bg-light d-inline-block rounded-circle p-3">
                                                    <span class="icon  mdi mdi-email d-flex align-items-center justify-content-center theme-color transition"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-md-9">
                                            <div class="text-md-left text-center mt-lg-0 mt-4">
                                                <h5 class="theme-color f-20">Call:</h5>
                                                <a href="tel:+17866730790" class="text-dark text-chat">+178 66 730 790</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <form class="contact_form common-input-form mt-md-4">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input name="name" id="name" type="text" class="form-control border-0" placeholder="Your Name">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input name="email" id="email" type="email" class="form-control border-0" placeholder="Your Email">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input name="subject" id="subject" type="text" class="form-control border-0" placeholder="Subject">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <textarea name="comments" id="comments" rows="6" class="form-control border-0" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 text-left">
                                        <button type="submit" class="mt-4 btn theme-btn contact-form-btn border-0" value="Send">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Contact us -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //OWL CAROUSEL JS
    import 'owl.carousel/dist/assets/owl.carousel.css';
    import 'owl.carousel';
    export default {
        name: 'index', mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initMainSlider();
                this.initWowAnimation();
                this.initTeamSlider();
                this.initClientSlider();
                this.initAccordian();
            },
            /*----Main Slider----*/
            initMainSlider() {
                $('.main-slider').owlCarousel({
                    loop: true,
                    items: 1,
                    margin: 0,
                    nav: true,
                    dots: true,
                    autoplay: false,
                    autoplayTimeout: 5000,
                    items: 1,
                    responsiveClass: true,
                    responsive: {
                        0: {
                            items: 1,
                            nav: false,
                            dots: true
                        },
                        600: {
                            items: 1,
                            nav: false,
                            dots: true
                        },
                        1000: {
                            items: 1,
                            nav: false,
                            dots: true
                        }
                    }
                });
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            /*----TEAM SLIDER-----*/
            initTeamSlider() {
                var teamCarousel = $('.team-slider');
                if (teamCarousel.length > 0) {
                    teamCarousel.owlCarousel({
                        loop: true,
                        items: 1,
                        margin: 0,
                        nav: true,
                        dots: true,
                        autoplay: false,
                        autoplayTimeout: 5000,
                        items: 2,
                        responsiveClass: true,
                        responsive: {
                            0: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            600: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            991: {
                                items: 2,
                                nav: false,
                                dots: true
                            }
                        }
                    });
                }
            },
            /*----CLIENT SLIDER-----*/
            initClientSlider() {
                var testimonialCarousel = $('.clients-slider');
                if (testimonialCarousel.length > 0) {
                    testimonialCarousel.owlCarousel({
                        loop: true,
                        items: 1,
                        margin: 0,
                        nav: true,
                        dots: true,
                        autoplay: false,
                        autoplayTimeout: 5000,
                        items: 2,
                        responsiveClass: true,
                        responsive: {
                            0: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            600: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            991: {
                                items: 2,
                                nav: false,
                                dots: true
                            }
                        }
                    });
                }
            },
            /*----ACCORDIAN-----*/
            initAccordian() {
                $(".accordion-card").click(function () {
                    if ($(this).hasClass('active')) {
                        $('.accordion-card').removeClass('active');
                        $(this).removeClass('active')
                    } else {
                        $('.accordion-card').removeClass('active');
                        $(this).addClass('active')
                    }
                });
            }
        }
    }
</script>
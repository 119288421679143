<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">About Busiapp</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">About Us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Start Aboutus Top-->
        <section class="section about-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="title-section">
                            <h3>Design Is What You Do When You Want To express Yourself</h3>
                        </div>
                        <div class="para-text text-left mt-4">
                            <p>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book..</p>
                            <p>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book..</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="about-video-sec">
                            <div class="position-relative wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                                <img id="video-cover" src="assets/images/aboutus/about-inner.jpg" alt="Video title" class="w-100 img-fluid">
                                <a href="#myModal" class="play-btn transition d-flex" data-toggle="modal">
                                    <i class="mdi mdi-play mx-auto"></i>
                                </a>

                                <div id="myModal" class="modal wow fadeIn" data-wow-delay="0.2s" data-wow-duration="1s">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content bg-transparent">
                                            <div class="modal-body p-0">
                                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
                                                <iframe src="https://www.youtube.com/embed/Kch8n4tcOZQ" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Aboutus Top -->
        <!-- Start Aboutus Botttom -->
        <section class="section about-bottom bg-light">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-lg-0 mt-sm-4 mt-0">
                            <div class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="text-center">
                                    <div class="about-inner-text">
                                        <span class="icon transition mdi mdi-semantic-web theme-color f-40">
                                        </span>
                                        <h4 class="my-3 theme-color">Website Design</h4>
                                        <p class="para-text mb-0">It is a long established fact that a reader will be distracted by the readable.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-lg-0 mt-4">
                            <div class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="text-center">
                                    <div class="about-inner-text">
                                        <span class="icon transition mdi mdi-globe-model theme-color f-40">
                                        </span>
                                        <h4 class="my-3 theme-color">Website Development</h4>
                                        <p class="para-text mb-0">It is a long established fact that a reader will be distracted by the readable.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-lg-0 mt-4">
                            <div class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="text-center">
                                    <div class="about-inner-text">
                                        <span class="icon transition mdi mdi-rocket theme-color f-40">
                                        </span>
                                        <h4 class="my-3 theme-color">Launch Website</h4>
                                        <p class="para-text mb-0">It is a long established fact that a reader will be distracted by the readable.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Aboutus Botttom -->
        <!-- Start Clients Section -->
        <section class="clients-section section">
            <div class="container">
                <div class="title-section z-index position-relative wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1s">
                    <div class="subtitle-hr d-flex align-items-center">
                        <h6 class="subtitle text-white text-uppercase position-relative">TESTIMONIALS</h6>
                        <hr class="white-border">
                    </div>
                    <h2 class="title text-md-left text-center f-40 text-white">Client Says</h2>
                </div>
                <div class="mt-4 owl-carousel owl-theme clients-slider wow fadeIn" data-wow-duration="1000ms">
                    <div class="px-3 item wow fadeIn" data-wow-delay="0.2s" data-wow-duration="1s">
                        <div class="user-pics overflow-hidden mx-auto">
                            <img src="assets/images/clients/client-01.png" alt="" class="img-fluid">
                        </div>
                        <div class="clients-card bg-white">
                            <div class="user-txt text-sm-left text-center">
                                <div class="use-info">
                                    <h5 class="theme-color">Alee Tie</h5>
                                    <p class="f-14">Company CEO</p>
                                </div>
                            </div>
                            <div class="shadow-md">
                                <p class="m-0 text-sm-left text-center f-16 font-weight-medium"><em> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. the readable content of a page when looking at its layout.</em> </p>
                            </div>
                        </div>
                    </div>
                    <div class="px-3 item wow fadeIn" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="user-pics overflow-hidden mx-auto">
                            <img src="assets/images/clients/client-02.png" alt="" class="img-fluid">
                        </div>
                        <div class="clients-card bg-white">
                            <div class="user-txt text-sm-left text-center">
                                <div class="use-info">
                                    <h5 class="theme-color">Kalvin Stafrd</h5>
                                    <p class="f-14">Company CEO</p>
                                </div>
                            </div>
                            <div class="shadow-md">
                                <p class="m-0 text-sm-left text-center f-16 font-weight-medium"><em> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. the readable content of a page when looking at its layout.</em> </p>
                            </div>
                        </div>
                    </div>
                    <div class="px-3 item wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                        <div class="user-pics overflow-hidden">
                            <img src="assets/images/clients/client-03.png" alt="" class="img-fluid">
                        </div>
                        <div class="clients-card bg-white">
                            <div class="user-txt text-sm-left text-center">
                                <div class="use-info">
                                    <h5 class="theme-color">Elie Johnson</h5>
                                    <p class="f-14">Company CEO</p>
                                </div>
                            </div>
                            <div class="shadow-md">
                                <p class="m-0 text-sm-left text-center f-16 font-weight-medium"><em> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. the readable content of a page when looking at its layout.</em> </p>
                            </div>
                        </div>
                    </div>
                    <div class="px-3 item wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                        <div class="user-pics overflow-hidden mx-auto">
                            <img src="assets/images/clients/client-02.png" alt="" class="img-fluid">
                        </div>
                        <div class="clients-card bg-white">
                            <div class="user-txt text-sm-left text-center">
                                <div class="use-info">
                                    <h5 class="theme-color">Elie Johnson</h5>
                                    <p class="f-14">Company CEO</p>
                                </div>
                            </div>
                            <div class="shadow-md">
                                <p class="m-0 text-sm-left text-center f-16 font-weight-medium"><em> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. the readable content of a page when looking at its layout.</em> </p>
                            </div>
                        </div>
                    </div>
                    <div class="px-3 item wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                        <div class="user-pics overflow-hidden mx-auto">
                            <img src="assets/images/clients/client-01.png" alt="" class="img-fluid">
                        </div>
                        <div class="clients-card bg-white">
                            <div class="user-txt text-sm-left text-center">
                                <div class="use-info">
                                    <h5 class="theme-color">Elie Johnson</h5>
                                    <p class="f-14">Company CEO</p>
                                </div>
                            </div>
                            <div class="shadow-md">
                                <p class="m-0 text-sm-left text-center f-16 font-weight-medium"><em> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. the readable content of a page when looking at its layout.</em> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Clients Section -->
        <!-- Start Counter Section -->
        <section class="section">
            <div class="container">
                <div id="counter">
                    <div class="row">
                        <div class="col-sm-3 col-6 my-3 wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                            <div class="text-center item">
                                <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                    <span class="icon transition mdi mdi-rocket d-flex align-items-center justify-content-center text-white f-40"></span>
                                </div>
                                <h3 id="number1" class="number f-40 my-2" data-count="500">0</h3>
                                <h5 class="mb-0">Projects</h5>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6 my-3 wow fadeIn" data-wow-delay="0.3s" data-wow-duration="1s">
                            <div class="text-center item">
                                <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                    <span class="icon transition mdi mdi-account-group d-flex align-items-center justify-content-center text-white f-40"></span>
                                </div>
                                <h3 id="number2" class="number f-40 my-2" data-count="1000">0</h3>
                                <h5 class="mb-0">Satisfied clients</h5>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6 my-3 wow fadeIn" data-wow-delay="0.6s" data-wow-duration="1s">
                            <div class="text-center item">
                                <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                    <span class="icon transition mdi mdi mdi-coffee d-flex align-items-center justify-content-center text-white f-40"></span>
                                </div>
                                <h3 id="number3" class="number f-40 my-2" data-count="50">0</h3>
                                <h5 class="mb-0">Awards</h5>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6 my-3 wow fadeIn" data-wow-delay="0.9s" data-wow-duration="1s">
                            <div class="text-center item">
                                <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                    <span class="icon transition mdi mdi-heart d-flex align-items-center justify-content-center text-white f-40"></span>
                                </div>
                                <h3 id="number4" class="number f-40 my-2" data-count="500">0</h3>
                                <h5 class="mb-0">Followers</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Counter Section -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    export default {
        name: 'about',
        mounted() {
            this.init();
        },
        methods: {
            init() {               
                this.initWowAnimation();
                this.initClientSlider();
                this.initCounter();
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },           
            /*----CLIENT SLIDER-----*/
            initClientSlider() {
                var testimonialCarousel = $('.clients-slider');
                if (testimonialCarousel.length > 0) {
                    testimonialCarousel.owlCarousel({
                        loop: true,
                        items: 1,
                        margin: 0,
                        nav: true,
                        dots: true,
                        autoplay: false,
                        autoplayTimeout: 5000,
                        items: 2,
                        responsiveClass: true,
                        responsive: {
                            0: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            600: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            991: {
                                items: 2,
                                nav: false,
                                dots: true
                            }
                        }
                    });
                }
            },
            /*----COUNTER-----*/
            initCounter() {
                $.fn.jQuerySimpleCounter = function (options) {
                    var settings = $.extend({
                        start: 0,
                        end: 100,
                        easing: 'swing',
                        duration: 400,
                        complete: ''
                    }, options);

                    var thisElement = $(this);

                    $({
                        count: settings.start
                    }).animate({
                        count: settings.end
                    }, {
                        duration: settings.duration,
                        easing: settings.easing,
                        step: function () {
                            var mathCount = Math.ceil(this.count);
                            thisElement.text(mathCount);
                        },
                        complete: settings.complete
                    });
                };
                $('#number1').jQuerySimpleCounter({
                    end: 500,
                    duration: 3000
                });
                $('#number2').jQuerySimpleCounter({
                    end: 1000,
                    duration: 3000
                });
                $('#number3').jQuerySimpleCounter({
                    end: 50,
                    duration: 2000
                });
                $('#number4').jQuerySimpleCounter({
                    end: 8899,
                    duration: 2500
                });
            }
        }
    }
</script>
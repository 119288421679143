<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Contact Us</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">Contact Us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Google Map Section Start-->
        <div class="p-0">
            <div class="google-map" style="width:100%;">
                <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100" height="300" allowfullscreen></iframe>
            </div>
        </div>
        <!-- Google Map Section End-->
        <!-- Contact Section Start-->
        <section class="section section-contactus contactus-section">
            <div class="container">

                <div class="wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                    <div class="title-section">
                        <div class="subtitle-hr d-flex align-items-center">
                            <h6 class="subtitle theme-color text-uppercase position-relative">Contact</h6>
                            <hr>
                        </div>
                        <h2 class="title text-md-left text-center f-40">Get in touch with us</h2>
                    </div>
                    <div class="mt-4">
                        <div class="row">
                            <div class="col-sm-6 text-sm-left text-center">
                                <h6 class="green-color text-uppercase">Visit Our Office</h6>
                                <h3 class="text-dark res-h3-small">
                                    <a class="text-dark" href="https://www.google.com/maps" target="_blank">
                                        A108 Adam Street,<br> New York, NY 535022
                                    </a>
                                </h3>
                            </div>
                            <div class="col-sm-6 mt-sm-0 mt-4 text-sm-left text-center">
                                <h6 class="green-color text-uppercase">Or Send Mail Us</h6>
                                <h3 class="text-dark res-h3-small">
                                    <a href="mailto:info@yourdomain.com" class="text-dark">
                                        info@yourdomian.com
                                    </a>,
                                    <br>
                                    <a href="tel:+19876543210" class="text-dark">+1 XXX XXX XXXX</a>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <form class="common-input-form mt-3">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input name="name" id="name" type="text" class="form-control border-0" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input name="email" id="email" type="email" class="form-control border-0" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input name="subject" id="subject" type="text" class="form-control border-0" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <textarea name="comments" id="comments" rows="2" class="form-control border-0" placeholder="Message"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="mt-2 btn theme-btn contact-form-btn border-0" value="Send">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <!-- Contact Section End-->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    export default {
        name: 'contact',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initWowAnimation();              
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            } 
        }
    }
</script>
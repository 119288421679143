<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Blog Details</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">Blog Details</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Blog Details Start-->
        <div class="section section-blog">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 order-lg-2 wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                        <aside class="sidebar sidebar-padding bg-white border">
                            <div class="input-group">
                                <input class="form-control search-input pl-0" placeholder="Search..." type="text">
                                <button type="submit" class="btn btn-search p-0">
                                    <span class="mdi mdi-magnify theme-color"></span>
                                </button>
                            </div>
                            <div class="pb-2 blog-side-padding">
                                <div class="blog-news d-flex align-items-center list-unstyled mb-2">
                                    <h4 class="title f-16 pb-2 text-uppercase theme-color">RECENT POSTS</h4>
                                </div>
                                <div class="blog-news-content">
                                    <div class="blog-post-list">
                                        <ul class="list-unstyled">
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Map where your photos taken</a>
                                                    <a href="#" class="post-date d-block font-weight-regular grey-text"> March 6, 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Explore Vancouver Mountain</a>
                                                    <a href="#" class="post-date d-block font-weight-regular grey-text"> May 1, 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Become Who You Say You Always Will</a>
                                                    <a href="#" class="post-date d-block font-weight-regular grey-text"> February 27, 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Brunch at Terrine</a>
                                                    <a href="#" class="post-date d-block font-weight-regular grey-text">January 20, 2020</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="pb-2">
                                <div class="blog-news d-flex align-items-center list-unstyled mb-2">
                                    <h6 class="title pb-2 text-uppercase theme-color">RECENT POSTS</h6>
                                </div>
                                <div class="blog-news-content">
                                    <div class="blog-post-list">
                                        <ul class="list-unstyled">
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Journey</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Development</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Sport</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Photography</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Symphony (5)</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="pb-2">
                                <div class="blog-news d-flex align-items-center list-unstyled mb-2">
                                    <h6 class="title pb-2 text-uppercase theme-color">RECENT POSTS</h6>
                                </div>
                                <div class="blog-news-content">
                                    <div class="blog-post-list">
                                        <ul class="list-unstyled">
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">February 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">January 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">December 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">November 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">October 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">July 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">June 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">May 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">April 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">February 2020</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">January 2020</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tags-post">
                                <div class="blog-news d-flex align-items-center list-unstyled mb-2">
                                    <h6 class="title pb-2 text-uppercase theme-color">RECENT POSTS</h6>
                                </div>

                                <div class="blog-news-content">
                                    <div class="blog-post-list">
                                        <ul class="list-unstyled list-inline mb-0">
                                            <li class="list-inline-item">
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Journey ,</a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Development ,</a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-post-info">
                                                    <a href="#" class="text-dark font-weight-regular">Sport</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div class="col-lg-8 order-lg-1 mt-lg-0 mt-4 wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                        <article class="blog-post-left bg-white border">
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="blog-post-content text-left">
                                        <h6 class="subtitle text-uppercase theme-color">Travel</h6>
                                        <h4>
                                            <a href="#" class="text-left text-dark">
                                                This is a stardard post with preview image
                                            </a>
                                        </h4>
                                        <h6>
                                            <a href="#" class="text-center grey-text">
                                                May 10, 2020
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="post-image mb-2">
                                        <a href="#">
                                            <img src="assets/images/blog/blog-02.jpg" class="img-fluid img-thumbnail border-0" alt="">
                                        </a>
                                    </div>
                                    <div class="post-content">
                                        <p class="f-16 grey-text font-weight-regular">Euismod atras vulputate iltricies etri elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla nunc dui, tristique in semper vel, congue sed ligula. Nam dolor ligula, faucibus id sodales in, auctor fringilla libero. Pellentesque pellentesque tempor tellus eget hendrerit. Morbi id aliquam ligula. Aliquam id dui sem. Proin rhoncus consequat nisl, eu ornare mauris tincidunt vitae.</p>
                                        <p class="f-16 grey-text font-weight-regular">Aliquam id dui sem. Proin rhoncus consequat nisl, eu ornare mauris tincidunt vitae Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla nunc dui, tristique in semper vel, congue sed ligula. Nam dolor ligula, faucibus id sodales in, auctor fringilla libero. Pellentesque pellentesque tempor tellus eget hendrerit. Morbi id aliquam ligula. Aliquam id dui sem. Proin rhoncus consequat nisl, eu ornare mauris tincidunt vitae.</p>
                                    </div>
                                    <div class="post-footer border-top mt-4 pt-4">
                                        <div class="row">
                                            <div class="col-sm-6 text-sm-left text-center">
                                                <div class="post-tag-label">
                                                    <span class="theme-color font-weight-regular mr-3">Tags :</span>
                                                    <a href="#" class="grey-text font-weight-regular mr-3">Atractive ,</a>
                                                    <a href="#" class="grey-text font-weight-regular">Design</a>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 text-sm-right text-center mt-sm-0 mt-3">
                                                <div class="social-media-btns">
                                                    <a href="#">
                                                        <i class="mdi mdi-facebook grey-text f-16 mr-2"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i class="mdi mdi-twitter grey-text f-16 mr-2"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i class="mdi mdi-instagram grey-text f-16 mr-2"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i class="mdi mdi-gmail grey-text f-16 mr-2"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
        <!-- Blog Details Section End-->
    </div>
</template>
<script>
    export default {
        name: 'blogdetail',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.hidePreloader();
                              
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
        }
    }
</script>
<template>
    <div>
        <!-- Start Footer -->
        <footer class="section footer position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-3 mt-sm-0 mt-4">
                        <ul class="list-unstyled mb-0">
                            <li>
                                <h5 class="text-white">Contact</h5>
                                <a href="tel:+17866730790" class="text-white transition">+178 66 730 790</a>
                            </li>
                            <li class="mt-4">
                                <h5 class="text-white">Working Time</h5>
                                <a href="#" class="text-white mb-0 transition">We are open 24/7</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-5 col-sm-5 link-border-right mt-sm-0 mt-4">
                        <h5 class="footer-title text-white">Links</h5>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6">
                                <ul class="footer_menu_list list-unstyled mb-0">
                                    <li>
                                        <a href="/" class="text-white f-14 d-inline-block transition">Home</a>
                                    </li>
<!--                                    <li>
                                        <a href="#Aboutus" class="text-white f-14 d-inline-block transition">About Us</a>
                                    </li>-->
                                    <li>
                                        <a href="#faq-here" class="text-white f-14 d-inline-block transition">Faq</a>
                                    </li>
                                </ul>
                            </div>
<!--                            <div class="col-lg-4 col-sm-6">
                                <ul class="footer_menu_list list-unstyled mb-0">
                                    <li>
                                        <a href="#how_it_works" class="text-white f-14 d-inline-block transition">How it works</a>
                                    </li>
                                    <li>
                                        <a href="#Clients" class="text-white f-14 d-inline-block transition">Clients</a>
                                    </li>
                                    <li>
                                        <a href="#Contactus" class="text-white f-14 d-inline-block transition">Contact</a>
                                    </li>
                                </ul>
                            </div>-->
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 my-sm-0 mt-4">
<!--                        <h5 class="footer-title text-white text-sm-right text-left">Follow Us</h5>
                        <div class="footer_menu_list list-unstyled mb-0 text-sm-right text-left">
                            <a href="#" class="transition icn-block d-inline-block transition">
                                <i class="icon pr-2 mdi mdi-facebook text-white f-34 d-inline-block"></i>
                            </a>
                            <a href="#" class="transition icn-block d-inline-block transition">
                                <i class="icon pr-2 mdi mdi-twitter text-white  f-34 d-inline-block"></i>
                            </a>
                            <a href="#" class="transition icn-block d-inline-block transition">
                                <i class="icon pr-2 mdi mdi-google-plus text-white f-34 d-inline-block"></i>
                            </a>
                        </div>-->
                        <div class="pt-4">
                            <p class="mb-0 text-sm-right text-left text-white"> &copy; 2024 Westamy.com. All Rights Reserved.</p>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
        <!-- End Footer -->
        <!-- Back To Top -->
        <a href="#" class="back_top rounded-circle text-center transition"><i class="mdi mdi-monitor-dashboard d-block text-white"></i></a>
    </div>
</template>
<script>
    export default {
        name: "busiapp_footer",
        methods: {
        }
    }</script>

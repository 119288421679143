<template>
    <!-- Start Header -->
    <header>
        <!--Start Navbar-->
        <nav class="navbar navbar-expand-lg navbar-inner fixed-top custom-nav sticky w-100 rounded-0 mb-0">
            <div class="menu-overlay"></div>
            <div class="container">
                <!-- LOGO -->
                <a class="navbar-brand brand-logo mr-4" href="index.html">
                    <img src="assets/images/logo.png" class="img-fluid logo-light" alt="">
                    <img src="assets/images/logo-dark.png" class="img-fluid logo-dark" alt="">
                </a>
                <div class="navbar-collapse collapse justify-content-end mr-3" id="navbarCollapse">
                    <ul class="navbar-nav navbar-center">
                        <li class="nav-item active">
                            <router-link class="nav-link text-white transition text-uppercase" to="/">Home</router-link>
                        </li>
<!--                        <li class="nav-item">
                            <router-link class="nav-link text-white transition text-uppercase" to="/about">About Us</router-link>
                        </li>-->
<!--                        <li class="nav-item">
                            <router-link class="nav-link text-white transition text-uppercase" to="/services">Services</router-link>
                        </li>-->
<!--                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-white transition text-uppercase" href="javascript:void(0)" data-toggle="dropdown">
                                Portfolio
                            </a>
                            <ul class="nav-bar submenu dropdown-menu dropdown-menu-right list-unstyled p-0" aria-labelledby="navbarDropdown">
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/portfolio3">3 Column</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/portfolio4">4 Column</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/portfolio5">5 Column</router-link>
                                </li>
                            </ul>
                        </li>-->
<!--                        <li class="nav-item">
                            <router-link class="nav-link text-white transition text-uppercase" to="/blog">Blog</router-link>
                        </li>-->
<!--                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle text-white transition text-uppercase" href="javascript:void(0)" data-toggle="dropdown">
                                Pages
                            </a>
                            <ul class="nav-bar submenu dropdown-menu dropdown-menu-right list-unstyled p-0" aria-labelledby="navbarDropdown">
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/blogdetail">Blog Details</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/faq">FAQ</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/error">404</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/login">Login</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/signup">Register</router-link>
                                </li>
                            </ul>
                        </li>-->
                    </ul>
                </div>
                <div class="contact_btn">
                    <a class="btn theme-btn text-uppercase d-flex justify-content-center align-items-center" href="#contact-here">Contact</a>
                    <button class="navbar-toggler ml-2 p-0 mb-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="mdi mdi-menu"></i>
                    </button>
                </div>
            </div>
        </nav>
        <!-- End Navbar -->
    </header>
    <!-- End Header -->
</template>

<script>
    export default {
        name: "busiapp_header",
        methods: {
        }
    }</script>
//IMPORT HEADER
import busiapp_header from "./components/header";
//IMPORT FOOTER
import busiapp_footer from "./components/footer";

export default {
    name: 'App',
    created() {
        document.title = "WESTAMY | Digital Business Agency";
    },
    components: {
        busiapp_header,
        busiapp_footer
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.initDropdownHover();
            this.windowScroll();
            this.initMenuactive();
        },
        /*----DROPDOWN HOVER JS-----*/
        initDropdownHover() {
            $(".dropdown").on('mouseover', function () {
                $('.dropdown-menu', this).stop(true, true).fadeIn("fast");
                $(this).addClass('open');
            });
            $(".dropdown").on('mouseleave', function () {
                $('.dropdown-menu', this).stop(true, true).fadeOut("fast");
                $(this).removeClass('open');
            });
        },
        initMenuactive() {
            $(".navbar-collapse .nav-item").click(function () {
                $('.navbar-collapse').children().find('.nav-item').removeClass('active');
                $(this).addClass('active');
            });
        },
        /*----ONSCROLL JS-----*/
        windowScroll() {
            var self = this;
            $(window).on("scroll", function () {
                self.initSectionScroll();
            });
        },
        /*----SECTION SCROLL JS-----*/
        initSectionScroll() {
            $(window).scrollTop() >= 20 ? $("nav").addClass("sticky-header") : $(".sticky").removeClass("sticky-header");
        }
    }
}
<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Services</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">Services</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Start Services Top -->
        <section class="section services-top">
            <div class="container">
                <div class="services-main">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <img src="assets/images/services/service-tab.png" alt="service-tab.png" class="w-100 img-fluid">
                        </div>
                        <div class="col-md-6 mt-md-0 mt-4">
                            <div class="services-inner">
                                <div class="title-section">
                                    <div class="subtitle-hr d-flex align-items-center">
                                        <h6 class="subtitle theme-color text-uppercase position-relative">SERVICES</h6>
                                        <hr>
                                    </div>
                                    <h2 class="title text-md-left text-center f-40">We Will Ready For Your Services</h2>
                                </div>
                                <div class="service-box pt-4 d-flex align-items-start">
                                    <div class="index-box mr-3 z-index">
                                        <h4 class="green-color">01</h4>
                                    </div>
                                    <div class="services-info z-index">
                                        <h6 class="text-secondary">Powerful System</h6>
                                        <p class="font-weight-regular grey-text">There are many variations of passages of Lorem Ipsum available, but the ma- jority have suffered.</p>
                                    </div>
                                </div>
                                <div class="service-box pt-4 d-flex align-items-start">
                                    <div class="index-box mr-3 z-index">
                                        <h4 class="green-color">02</h4>
                                    </div>
                                    <div class="services-info z-index">
                                        <h6 class="text-secondary">Personalization</h6>
                                        <p class="font-weight-regular grey-text">There are many variations of passages of Lorem Ipsum available, but the ma- jority have suffered.</p>
                                    </div>
                                </div>
                                <div class="service-box pt-4 d-flex align-items-start">
                                    <div class="index-box mr-3 z-index">
                                        <h4 class="green-color">03</h4>
                                    </div>
                                    <div class="services-info z-index">
                                        <h6 class="text-secondary">Ease Of Access</h6>
                                        <p class="font-weight-regular grey-text">There are many variations of passages of Lorem Ipsum available, but the ma- jority have suffered.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Services Top -->
        <!-- Start Aboutus Parallex -->
        <section class="section about-parallex">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="about-feature-main mt-lg-0 mt-5 d-flex justify-content-start">
                            <div class="index-box mr-3 z-index">
                                <h4 class="number green-color">01</h4>
                            </div>
                            <div class="about-feature-info z-index">
                                <h5 class="text-white">Unlimited Colors</h5>
                                <p class="text-white mb-0">Freely try the colors until there�s one matching the spirit of your business.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="about-feature-main mt-lg-0 mt-5 d-flex justify-content-start">
                            <div class="index-box mr-3 z-index">
                                <h4 class="number green-color">02</h4>
                            </div>
                            <div class="about-feature-info z-index">
                                <h5 class="text-white">Unlimited Colors</h5>
                                <p class="text-white mb-0">Freely try the colors until there�s one matching the spirit of your business.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="about-feature-main mt-lg-0 mt-5 d-flex justify-content-start">
                            <div class="index-box mr-3 z-index">
                                <h4 class="number green-color">03</h4>
                            </div>
                            <div class="about-feature-info z-index">
                                <h5 class="text-white">Unlimited Colors</h5>
                                <p class="text-white mb-0">Freely try the colors until there�s one matching the spirit of your business.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="about-feature-main mt-5 d-flex justify-content-start">
                            <div class="index-box mr-3 z-index">
                                <h4 class="number green-color">04</h4>
                            </div>
                            <div class="about-feature-info z-index">
                                <h5 class="text-white">Unlimited Colors</h5>
                                <p class="text-white mb-0">Freely try the colors until there�s one matching the spirit of your business.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="about-feature-main mt-5 d-flex justify-content-start">
                            <div class="index-box mr-3 z-index">
                                <h4 class="number green-color">05</h4>
                            </div>
                            <div class="about-feature-info z-index">
                                <h5 class="text-white">Unlimited Colors</h5>
                                <p class="text-white mb-0">Freely try the colors until there�s one matching the spirit of your business.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="about-feature-main mt-5 d-flex justify-content-start">
                            <div class="index-box mr-3 z-index">
                                <h4 class="number green-color">06</h4>
                            </div>
                            <div class="about-feature-info z-index">
                                <h5 class="text-white">Unlimited Colors</h5>
                                <p class="text-white mb-0">Freely try the colors until there�s one matching the spirit of your business.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Aboutus Parallex -->
        <!-- Start Features section -->
        <section class="section">
            <div class="container">
                <div class="title-section">
                    <div class="subtitle-hr d-flex align-items-center">
                        <h6 class="subtitle theme-color text-uppercase position-relative">Features</h6>
                        <hr>
                    </div>
                    <h2 class="title text-md-left text-center f-40">We Are Here For Made Your Idea</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-main text-center">
                                    <div class="features-inner-text text-sm-left text-center">
                                        <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                            <span class="icon transition mdi mdi-desktop-mac-dashboard d-flex align-items-center justify-content-center text-white f-40"></span>
                                        </div>
                                        <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Clean Design</h5>
                                        <p class="para-text text-sm-left text-center mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-responsive d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Responsive Design</h5>
                                    <p class="para-text text-sm-left text-center mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-rocket d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Fast Boot</h5>
                                    <p class="para-text text-sm-left text-center mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-chat-processing d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Chatting Service</h5>
                                    <p class="para-text text-sm-left text-center mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-chart-areaspline d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Analysis</h5>
                                    <p class="para-text text-sm-left text-center mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition  mdi mdi-face-agent d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Quick Support</h5>
                                    <p class="para-text text-sm-left text-center mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Features section -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
   
    export default {
        name: 'services',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initWowAnimation();             
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            }
        }
    }
</script>
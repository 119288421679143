<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Portfolio-5-columns</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">Portfolio-5-columns</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- portfolio Grid-col-5 Start -->
        <section class="section portfolio-section grid-col-5">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-12 wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1s">
                        <div class="title-section">
                            <h2 class="text-capitalize text-center">Portfolio with basic-5 grid</h2>
                            <p class="text-center font-weight-regular grey-text mt-4 mb-0">
                                Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla non pulvinar.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="filters portfolio-filter my-5 text-center wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                    <ul class="p-0 list-unstyled d-flex align-items-center justify-content-center flex-wrap">
                        <li class="active py-3 mx-3" data-filter="*">All</li>
                        <li class="py-3 mx-3" data-filter=".Brands">Brands</li>
                        <li class="py-3 mx-3" data-filter=".Graphic">Graphic</li>
                        <li class="py-3 mx-3" data-filter=".Illust">Illustration</li>
                        <li class="py-3 mx-3" data-filter=".Photo">Photo</li>
                    </ul>
                </div>

                <div class="filters-content wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
                    <div class="row portfolio-items grid-5">

                        <div class="col col-sm-6 col-lg-3 all Graphic">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-1.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-1.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Brands">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-2.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-2.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Illust">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-3.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-3.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Photo">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-4.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-4.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Graphic">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-1.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-1.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Brands">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-2.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-2.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Illust">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-3.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-3.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Photo">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-4.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-4.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Illust">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-3.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-3.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-6 col-lg-3 all Photo">
                            <div class="item shadow text-center port-item">
                                <img src="assets/images/portfolio/p-4.jpg" alt="Work 1" class="img-fluid">
                                <div class="magnific-icon">
                                    <a href="assets/images/portfolio/p-4.jpg" class="js-zoom-gallery"> <i class="mdi mdi-magnify-plus-outline"></i> </a>
                                </div>
                                <div class="portfolio-hover">
                                    <h5 class="text-capitalize title-01">
                                        <a href="#" class="green-color"> Portfolio Title 01</a>
                                    </h5>
                                    <h5 class="text-capitalize title-02">
                                        <a href="#" class="text-white"> Portfolio Title 02</a>
                                    </h5>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <!-- portfolio Grid-col-5 End -->
    </div>
</template>
<script>
    //ISOTOP JS
    import '../assets/js/jquery.isotope.min.js';
    //MAGNIFIC POPUP JS
    import 'magnific-popup/dist/jquery.magnific-popup.min.js';
    import 'magnific-popup/dist/magnific-popup.css';
    export default {
        name: 'portfolio',

        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initIsotop();
                this.initMagnificPopup();
            },
            /*----ISOTOP JS-----*/
            initIsotop() {
                var $container = $('.portfolio-items');
                $container.imagesLoaded(function () {
                    $container.isotope({
                        filter: '*',
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });
                });

                $('.portfolio-filter li').click(function () {
                    $('.portfolio-filter .current').removeClass('current');
                    $(this).addClass('current');
                    var selector = $(this).attr('data-filter');
                    $container.isotope({
                        filter: selector,
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });
                    return false;
                });
            },
            /*----MAGNIFIC POPUP JS-----*/
            initMagnificPopup() {
                if (('.portfolio-items').length > 0) {
                    $('.portfolio-items').each(function () {
                        $(this).magnificPopup({
                            delegate: '.js-zoom-gallery',
                            type: 'image',
                            gallery: {
                                enabled: true
                            }
                        });
                    });
                }
            }
        }
    }
</script>
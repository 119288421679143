<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Blog Grid</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">Blog Grid</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Blog Grid Section Start -->
        <section class="section">
            <div class="container">
                <div class="blog-post-grid">
                    <div class="row">
                        <!-- Start Blog Item -->
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-item mt-4 blog-item-padding">
                                <span class="date-lg font-weight-medium">10</span>
                                <div class="hover-thumb-img"></div>
                                <div class="blog-post-info position-relative">
                                    <a class="blog-post-subtitle text-uppercase font-weight-medium mb-3" href="#">Technology</a>
                                    <h4 class="blog-post-title my-3">
                                        <a href="#" class="font-weight-medium">Appropriately productize fully</a>
                                    </h4>
                                    <div class="blog-info-meta my-3">
                                        <a href="#" class="font-weight-medium">May 10, 2020</a>
                                    </div>
                                    <div class="blog-info-desc">
                                        <p class="font-weight-regular">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>
                                    <div class="blog-post-action mt-4">
                                        <a href="#" class="btn theme-dark-btn text-white font-weight-medium">Read Full Post</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Blog Item -->
                        <!-- Start Blog Item -->
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-item mt-4 blog-item-padding">
                                <span class="date-lg font-weight-medium">06</span>
                                <div class="hover-thumb-img"></div>
                                <div class="blog-post-info position-relative">
                                    <a class="blog-post-subtitle text-uppercase font-weight-medium mb-3" href="#">Science</a>
                                    <h4 class="blog-post-title my-3">
                                        <a href="#" class="font-weight-medium">Quickly formulate backend</a>
                                    </h4>
                                    <div class="blog-info-meta my-3">
                                        <a href="#" class="font-weight-medium">May 10, 2020</a>
                                    </div>
                                    <div class="blog-info-desc">
                                        <p class="font-weight-regular">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>
                                    <div class="blog-post-action mt-4">
                                        <a href="#" class="btn theme-dark-btn text-white font-weight-medium">Read Full Post</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Blog Item -->
                        <!-- Start Blog Item -->
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-item mt-4 blog-item-padding">
                                <span class="date-lg font-weight-medium">22</span>
                                <div class="hover-thumb-img"></div>
                                <div class="blog-post-info position-relative">
                                    <a class="blog-post-subtitle text-uppercase font-weight-medium mb-3" href="#">LIFE STYLE</a>
                                    <h4 class="blog-post-title my-3">
                                        <a href="#" class="font-weight-medium">Objectively extend extensive</a>
                                    </h4>
                                    <div class="blog-info-meta my-3">
                                        <a href="#" class="font-weight-medium">May 10, 2020</a>
                                    </div>
                                    <div class="blog-info-desc">
                                        <p class="font-weight-regular">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>
                                    <div class="blog-post-action mt-4">
                                        <a href="#" class="btn theme-dark-btn text-white font-weight-medium">Read Full Post</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Blog Item -->
                        <!-- Start Blog Item -->
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-item mt-4 blog-item-padding">
                                <span class="date-lg font-weight-medium">09</span>
                                <div class="hover-thumb-img"></div>
                                <div class="blog-post-info position-relative">
                                    <a class="blog-post-subtitle text-uppercase font-weight-medium mb-3" href="#">LIFE STYLE, TRAVEL</a>
                                    <h4 class="blog-post-title my-3">
                                        <a href="#" class="font-weight-medium">Appropriately re-engineer high</a>
                                    </h4>
                                    <div class="blog-info-meta my-3">
                                        <a href="#" class="font-weight-medium">May 10, 2020</a>
                                    </div>
                                    <div class="blog-info-desc">
                                        <p class="font-weight-regular">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>
                                    <div class="blog-post-action mt-4">
                                        <a href="#" class="btn theme-dark-btn text-white font-weight-medium">Read Full Post</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Blog Item -->
                        <!-- Start Blog Item -->
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-item mt-4 blog-item-padding">
                                <span class="date-lg font-weight-medium">28</span>
                                <div class="hover-thumb-img"></div>
                                <div class="blog-post-info position-relative">
                                    <a class="blog-post-subtitle text-uppercase font-weight-medium mb-3" href="#">TRAVEL</a>
                                    <h4 class="blog-post-title my-3">
                                        <a href="#" class="font-weight-medium">Progressively visualize enabled</a>
                                    </h4>
                                    <div class="blog-info-meta my-3">
                                        <a href="#" class="font-weight-medium">May 10, 2020</a>
                                    </div>
                                    <div class="blog-info-desc">
                                        <p class="font-weight-regular">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>
                                    <div class="blog-post-action mt-4">
                                        <a href="#" class="btn theme-dark-btn text-white font-weight-medium">Read Full Post</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Blog Item -->
                        <!-- Start Blog Item -->
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-item mt-4 blog-item-padding">
                                <span class="date-lg font-weight-medium">15</span>
                                <div class="hover-thumb-img"></div>
                                <div class="blog-post-info position-relative">
                                    <a class="blog-post-subtitle text-uppercase font-weight-medium mb-3" href="#">LIFE STYLE</a>
                                    <h4 class="blog-post-title my-3">
                                        <a href="#" class="font-weight-medium">Credibly implement users</a>
                                    </h4>
                                    <div class="blog-info-meta my-3">
                                        <a href="#" class="font-weight-medium">May 10, 2020</a>
                                    </div>
                                    <div class="blog-info-desc">
                                        <p class="font-weight-regular">simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>
                                    <div class="blog-post-action mt-4">
                                        <a href="#" class="btn theme-dark-btn text-white font-weight-medium">Read Full Post</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Blog Item -->
                    </div>
                </div>
                <div class="pagination  d-flex justify-content-center mt-5">
                    <ul class="pagination">
                        <li class="page-item text-center">
                            <a href="#" class="page-link rounded-0">
                                <i class="mdi mdi-chevron-left"></i>
                            </a>
                        </li>
                        <li class="page-item text-center active">
                            <a href="#" class="page-link rounded-0">1</a>
                        </li>
                        <li class="page-item text-center">
                            <a href="#" class="page-link rounded-0">2</a>
                        </li>
                        <li class="page-item text-center">
                            <a href="#" class="page-link rounded-0">3</a>
                        </li>
                        <li class="page-item text-center">
                            <a href="#" class="page-link rounded-0">
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- Blog Grid Section End -->
    </div>
</template>
<script>
    export default {
        name: 'blog',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initWowAnimation();                              
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            }
        }
    }
</script>